
<template>
  <div class="card-loop">
    <transition-group>
      <div
        v-for="(item, idx) in loopModules"
        :key="idx"
        class="loop-item"
        :style="item.style"
        @click="handleLoop(idx)"
      >
        <img :src="baseData[idx].cover" :alt="baseData[idx].cover" />
        <p class="title">{{ baseData[idx].title }}</p>
        <transition
          name="fade"
          enter-active-class="animate__animated animate__fadeInUp"
          leave-active-class="animate__animated animate__fadeOutDown"
        >
          <div v-show="idx === loopCenterIdx">
            <p class="content">{{ baseData[idx].desc }}</p>
            <p class="tool"><a href="#">查看更多</a></p>
          </div>
        </transition>
      </div>
    </transition-group>
  </div>
</template>

<style lang="scss" scoped>
.card-loop {
  width: 100%;
  height: 400px;

  margin-top: 170px;
  position: relative;
  .loop-item {
    width: calc(100% / 5);
    float: left;
    box-sizing: border-box;

    position: absolute;

    transition: all ease-out 0.5s;
    transform-origin: 50% 50%;

    img {
      width: 100%;
    }
    div {
      background: #fff;
      padding-bottom: 5px;
    }
    p.title {
      padding: 3px;
      color: #333;
      font-weight: bolder;
      transform: scale(0.9);
    }
    p.content {
      font-size: 6px;
      color: #999;
      overflow: hidden;
      display: -webkit-box; //将元素设为盒子伸缩模型显示
      -webkit-box-orient: vertical; //伸缩方向设为垂直方向
      -webkit-line-clamp: 2; //超出2行隐藏，并显示省略号
      transform: scale(0.9);
    }
    p.tool {
      text-align: center;
      transform: scale(0.7);
    }
  }
}
</style>

<script>
// 引入图片资源
import loop1 from '@/assets/images/colors/1.png';
import loop2 from '@/assets/images/colors/2.png';
import loop3 from '@/assets/images/colors/3.png';
import loop4 from '@/assets/images/colors/4.png';
import loop5 from '@/assets/images/colors/1.png';

/**
 * 根据 id 获取下标
 * @params arr object[]
 * @params id number
 * @result number n>=-1
 */
const findIdxById = (arr, id) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === id) {
      return i;
    }
  }
  return -1;
};

export default {
  data() {
    return {
      // 轮播数据
      baseData: [
        {
          desc:
            '可折叠式智能移动影院Royle-X展现了惊人的高科技技术和全球首创的可折叠设计，配备了柔宇科技自助研发的首款智能移动显示操作系统，设计非常人性化。可能会可折叠计自助研发的首款智能移动显示操作系统，设计非常人性化。可能会可折自助研发的首款智能移动显示操作系统，设计非常人性化。可能会可折自助研发的首款智能移动显示操作系统，设计非常人性化。可能会可折',
          title: '这里是标题',
          cover: loop1,
        },
        {
          desc:
            '可折叠式智能移动影院Royle-X展现了惊人的高科技技术和全球首创的可折叠设计，配备了柔宇科技自助研发的首款智能移动显示操作系统，设计非常人性化。可能会可折叠计自助研发的首款智能移动显示操作系统，设计非常人性化。可能会可折自助研发的首款智能移动显示操作系统，设计非常人性化。可能会可折自助研发的首款智能移动显示操作系统，设计非常人性化。可能会可折',
          title: '这里是标题',
          cover: loop2,
        },
        {
          desc:
            '可折叠式智能移动影院Royle-X展现了惊人的高科技技术和全球首创的可折叠设计，配备了柔宇科技自助研发的首款智能移动显示操作系统，设计非常人性化。可能会可折叠计自助研发的首款智能移动显示操作系统，设计非常人性化。可能会可折自助研发的首款智能移动显示操作系统，设计非常人性化。可能会可折自助研发的首款智能移动显示操作系统，设计非常人性化。可能会可折',
          title: '这里是标题',
          cover: loop3,
        },
        {
          desc:
            '可折叠式智能移动影院Royle-X展现了惊人的高科技技术和全球首创的可折叠设计，配备了柔宇科技自助研发的首款智能移动显示操作系统，设计非常人性化。可能会可折叠计自助研发的首款智能移动显示操作系统，设计非常人性化。可能会可折自助研发的首款智能移动显示操作系统，设计非常人性化。可能会可折自助研发的首款智能移动显示操作系统，设计非常人性化。可能会可折',
          title: '这里是标题',
          cover: loop4,
        },
        {
          desc:
            '可折叠式智能移动影院Royle-X展现了惊人的高科技技术和全球首创的可折叠设计，配备了柔宇科技自助研发的首款智能移动显示操作系统，设计非常人性化。可能会可折叠计自助研发的首款智能移动显示操作系统，设计非常人性化。可能会可折自助研发的首款智能移动显示操作系统，设计非常人性化。可能会可折自助研发的首款智能移动显示操作系统，设计非常人性化。可能会可折',
          title: '这里是标题',
          cover: loop5,
        },
      ],

      // 当前展示项
      loopCenterIdx: 2,

      // 轮播样式模板，用来循环赋值给 容器
      loopModules: [
        {
          loopIdx: 0,
          style: {
            'z-index': 7,
            background: '#fff',
            transform: `scale(${1})`,
            left: 'calc(100% / 5 * 0)',
            height: '320px',
            opacity: 0.8,
          },
        },
        {
          loopIdx: 1,
          style: {
            'z-index': 8,
            transform: `scale(${1.4})`,
            background: '#fff',
            left: 'calc(100% / 5 * .9)',
            height: '320px',
            opacity: 0.9,
          },
        },
        {
          loopIdx: 2,
          style: {
            transform: `scale(${1.7})`,
            'z-index': 9,
            background: '#fff',
            left: 'calc(100% / 5 * 2)',
            opacity: 1,
            'box-shadow': '0 0 5px #ccc',
            height: '370px',
          },
        },
        {
          loopIdx: 3,
          style: {
            transform: `scale(${1.4})`,
            'z-index': 8,
            background: '#fff',
            left: 'calc(100% / 5 * 3.1)',
            opacity: 0.9,
            height: '320px',
          },
        },
        {
          loopIdx: 4,
          style: {
            'z-index': 7,
            transform: `scale(${1})`,
            background: '#fff',
            left: 'calc(100% / 5 * 4)',
            height: '320px',
            opacity: 0.8,
          },
        },
      ],
    };
  },
  created(){
    // todo Ajax ...
  },  

  mounted() {
    // 初始化轮播
    this.initLoop();
    console.log('centerIdx = ', this.loopCenterIdx);
  },

  methods: {
    // 初始化轮播
    initLoop() {
      this.loopModules = this.loopModules.map((item, idx) => {
        item.desc = this.baseData[idx];
        return item;
      });
      console.log(this.loopModules);
    },

    // 下一张
    handleNext() {
      this.loopCenterIdx = this.loopCenterIdx + 1 > 4 ? 0 : this.loopCenterIdx + 1;
      this.loopModules = this.loopModules.map((item, idx) => {
        item.desc = this.baseData[idx];
        return item;
      });
      this.loopModules.unshift(this.loopModules.pop());
    },

    // 上一张
    handleLast() {
      this.loopCenterIdx = this.loopCenterIdx - 1 < 0 ? 4 : this.loopCenterIdx - 1;

      this.loopModules = this.loopModules.map((item, idx) => {
        item.desc = this.baseData[idx];
        return item;
      });
      this.loopModules.push(this.loopModules.shift());
    },

    // 点击容器
    handleLoop(checkId) {
      console.log('checkId = ', checkId, ', loopCenterIdx = ', this.loopCenterIdx);
      // 当前项
      if (checkId === this.loopCenterIdx) {
        return;
      }

      if (checkId > this.loopCenterIdx) {
        if (this.loopCenterIdx === 0 && checkId === 4) {
          return this.handleLast(checkId);
        } else {
          return this.handleNext(checkId);
        }
      }

      if (checkId < this.loopCenterIdx) {
        if (checkId === 0 && this.loopCenterIdx === 4) {
          return this.handleNext(checkId);
        } else {
          return this.handleLast(checkId);
        }
      }
    },
  },
};
</script>